import { Box, Spacer } from "native-base";
import React, { Fragment } from "react";

import { h4 } from "../../styles";

interface IGeneralButtonProps {
  disabled?: boolean;
  filled?: boolean;
  iconSize?: number;
  label: string;
  labelStyle?: React.CSSProperties;
  onPress?: () => void;
  showIcon?: boolean;
  style?: React.CSSProperties;
  signUp?: boolean;
  commentText?: string;
  commentOnPress?: () => void;
}

export const GeneralButton: React.FC<IGeneralButtonProps> = ({
  disabled,
  filled = true,
  iconSize,
  label,
  labelStyle,
  onPress = () => {},
  showIcon,
  style,
  signUp,
  commentText,
  commentOnPress,
}) => {
  const filledButton: React.CSSProperties =
    filled !== undefined && filled === true
      ? {
          borderWidth: 0,
        }
      : {};

  const defaultStyle: React.CSSProperties = {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 56px",
    borderRadius: 4,
    borderWidth: 1,
    height: 48,
    width: 300,
    ...style,
    ...filledButton,
  };

  const defaultFontStyle: React.CSSProperties =
    filled !== undefined && filled === true
      ? {
          fontWeight: "600",
          // color: THEME.colors.background,
        }
      : { fontWeight: "600" };

  return (
    <Fragment>
      <div style={{ ...defaultStyle, cursor: "pointer" }} onClick={onPress}>
        {/* {showIcon === true ? <AntDesign name={iconName} size={iconSize} color={iconColor} backgroundColor={iconFilledColor} /> : null} */}
        {showIcon === true && <i>{/* Render your icon here */}</i>}
        <Box
          backgroundColor={"blueGray.300"}
          px={"16"}
          py={"4"}
          borderRadius={"4"}
          width={400}
        >
          <span
            style={labelStyle !== undefined ? labelStyle : defaultFontStyle}
          >
            {disabled ? "Loading ..." : label}
          </span>
        </Box>
      </div>

      {signUp !== undefined && signUp !== true ? (
        <Box
          // backgroundColor={"amber.300"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {commentText !== undefined ? (
            <Fragment>
              <Spacer size={h4} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: 12 }}>Are you new?</span>
                <span style={{ margin: "0 8px" }}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={
                      commentOnPress !== undefined ? commentOnPress : undefined
                    }
                  >
                    <strong style={{ fontSize: 16 }}>{commentText}</strong>
                  </div>
                </span>
                <span>now!</span>
              </div>
            </Fragment>
          ) : null}
        </Box>
      ) : (
        <Fragment>
          {commentText !== undefined ? (
            <Fragment>
              <br />
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: 12 }}>
                  Already have an account? Go to
                </span>
                <span style={{ margin: "0 8px" }}>
                  <button
                    onClick={
                      commentOnPress !== undefined ? commentOnPress : undefined
                    }
                  >
                    <strong style={{ fontSize: 16 }}>{commentText}</strong>
                  </button>
                </span>
                <span>now!</span>
              </div>
            </Fragment>
          ) : null}
        </Fragment>
      )}
    </Fragment>
  );
};
