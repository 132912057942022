import { Box, HStack, Text, VStack } from "native-base";
import React, { useContext } from "react";

import { ContentContainer } from "../../components";
import { GlobalContext } from "../../context";

export const DashboardProfile = () => {
  const { globalState } = useContext(GlobalContext);
  const { clientEmail, clientName, clientAddress } = globalState;

  return (
    <ContentContainer>
      <Box backgroundColor={"white"} px={4} py={8} flex={1} borderRadius={8} shadow={"5"}>
        <VStack space={8}>
          <HStack space={8}>
            <Box flexDirection={"row"} justifyContent={"space-between"} width={120}>
              <Text fontWeight={"medium"}>Client Name</Text>
              <Text>:</Text>
            </Box>
            <Text fontWeight={"bold"}>{clientName}</Text>
          </HStack>
          <HStack space={8}>
            <Box flexDirection={"row"} justifyContent={"space-between"} width={120}>
              <Text fontWeight={"medium"}>Client Email</Text>
              <Text>:</Text>
            </Box>
            <Text fontWeight={"bold"}>{clientEmail}</Text>
          </HStack>
          <HStack space={8}>
            <Box flexDirection={"row"} justifyContent={"space-between"} width={120}>
              <Text fontWeight={"medium"}>Client Address</Text>
              <Text>:</Text>
            </Box>
            <Text fontWeight={"bold"}>{clientAddress}</Text>
          </HStack>
        </VStack>
      </Box>
    </ContentContainer>
  );
};
