import React from "react";

interface IListingTextProps {
  label: string;
  itemWidth?: number | string;
  flexValue?: number;
  textColor?: string;
}

export const ListingText: React.FC<IListingTextProps> = ({ label, flexValue, textColor }) => {
  const dynamicColor = textColor !== undefined ? { color: textColor } : { color: "#27272a" };
  return (
    <div
      style={{
        flex: flexValue !== undefined ? flexValue : 1,
        flexWrap: "wrap",
        justifyContent: "start",
        // backgroundColor: "lightgreen"
      }}>
      <span style={{ fontWeight: "500", fontSize: 16, ...dynamicColor }}>{label}</span>
    </div>
  );
};
