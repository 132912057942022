import CryptoJS from "crypto-js";
import { Box, Center, HStack, Input, Text } from "native-base";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomAlert, GeneralButton, Loading, LoginContainer, Spacer } from "../../components";
import {
  APP_VERSION,
  AUTH_LOGIN,
  ERROR_MESSAGE,
  GET_ALL_SAMPLE_OF_CLIENT,
  GET_CLIENT_PROFILE_BY_EMAIL,
  LOGIN,
  VERIFY_CLIENT_PERMISSION,
} from "../../constants";
import { GlobalContext } from "../../context";
import { h12, h24, h4, h8, w16, w2, w4, w8 } from "../../styles";
import { setLocalStorage } from "../../utils";

export const Login = () => {
  const navigate = useNavigate();
  const { handleInitSideMenu } = useContext(GlobalContext);

  const [fetching, setFetching] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleAuthLogin = async (email: string) => {
    const authRequest = {
      clientEmail: email,
    };

    try {
      const authPermissionResponse = await fetch(VERIFY_CLIENT_PERMISSION, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(authRequest),
      });
      const authPermissionContent = await authPermissionResponse.json();

      if (authPermissionContent.data.message === "Success" && authPermissionContent.data.result.data.isPermitted === true) {
        return true;
      } else {
        setError(true);
        setErrorMessage("Permission denied. You don't have access to the system.");
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorMessage(error);
    }
  };

  const handleGetClientId = async (email: string) => {
    // get client profile
    const request = {
      clientEmail: email,
    };

    try {
      const clientResponse = await fetch(GET_CLIENT_PROFILE_BY_EMAIL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
      const clientContent = await clientResponse.json();

      if (clientContent.data.message === "Success") {
        const clientProfileData = clientContent.data.result.data;
        //   console.log("clientContent.data", clientContent.data);
        //   setClientId(clientIdRes);
        return clientProfileData;
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorMessage(error);
    }
  };

  const handleGetAllSampleOfClient = async (clientId: string) => {
    const request = {
      clientId: clientId,
    };
    try {
      const samplesResponse = await fetch(GET_ALL_SAMPLE_OF_CLIENT, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
      const samplesContent = await samplesResponse.json();

      if (samplesContent.data.message === "Success") {
        const samplesOfClient = samplesContent.data.result.sampleItems;
        return samplesOfClient;
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET CLIENT PROFILE");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleGetData = async (email: string) => {
    // get profile here
    const clientProfile = await handleGetClientId(email);
    // console.log("clientProfile", clientProfile);

    setLocalStorage("client-email", clientProfile.clientEmail);
    setLocalStorage("client-id", clientProfile.clientId);

    const clientSamples = await handleGetAllSampleOfClient(clientProfile.clientId);
    // // const clientEmail = clientProfile.clientEmail;
    // // const clientSamples = await handleGetAllSampleOfClient(email);
    // console.log("clientSamples", clientSamples);
    // console.log("clientSamples", clientSamples);

    await handleInitSideMenu(clientProfile, clientSamples);
  };

  const loginHelper = async (email: string) => {
    const userVerified = await handleAuthLogin(email);
    //   console.log("userVerified", userVerified);
    if (typeof userVerified === "boolean" && userVerified === true) {
      const authRequest = {
        email: email,
        password: CryptoJS.AES.encrypt(password, "P@E!$%TROLAB+").toString(),
      };
      try {
        const authSignInResponse = await fetch(AUTH_LOGIN, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(authRequest),
        });
        // const authSignInResponse: any = await handleLoginFromClient();
        const authSignInContent = await authSignInResponse.json();
        // console.log("authSignInContent", authSignInContent);
        if (authSignInContent.data.message === "Success") {
          // set token to ref
          const receivedToken = authSignInContent.data.result.idToken;
          setLocalStorage("id-token", receivedToken);
          // localStorage.setItem("id-token", receivedToken);

          // fetch data api
          await handleGetData(email);
          // go to dashboard
          navigate("/Dashboard");
        } else {
          // console.log("failed to add new parameter");
          setError(true);
          setErrorMessage("ERROR_MESSAGE: FAILED TO LOGIN");
        }
      } catch (error) {
        // console.log(error);
        setError(true);
        setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
      }
    } else {
      return false;
    }
  };

  const handleResetCredential = () => {
    setEmail("");
    setPassword("");
  };

  const handleCheckGoogleAccount = async () => {
    setFetching(true);

    if (email !== "" && password !== "") {
      await loginHelper(email);
    } else {
      setError(true);
      setErrorMessage(ERROR_MESSAGE.INPUT_CREDENTIAL);
    }

    setFetching(false);
    handleResetCredential();
  };

  const handleGoToSignUp = () => {
    navigate("/signup");
  };

  const handleGoToResetPassword = () => {
    navigate("/reset-password");
  };

  const handleUpdateShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <Center>
      <LoginContainer title={"PetroLab"} subtitle={APP_VERSION} subLabel={"Sign in to PetroLab monitoring system"}>
        <Box>
          <Box marginBottom={h8}>
            <Input
              autoCapitalize={"none"}
              onChangeText={setEmail}
              placeholder={LOGIN.PLACEHOLDER_EMAIL}
              value={email}
              variant="filled"
              style={{
                padding: w16,
                borderRadius: 4,
                width: "auto",
              }}
            />

            <Box height={h4} />

            <Input
              maxLength={8}
              onChangeText={setPassword}
              placeholder={LOGIN.PLACEHOLDER_PASSWORD}
              // secureTextEntry={true}
              value={password}
              variant="filled"
              type={showPassword ? "text" : "password"}
              InputRightElement={
                <div onClick={handleUpdateShowPassword} style={{ cursor: "pointer", paddingRight: w4 }}>
                  <Text>{showPassword ? "hide" : "show"}</Text>
                </div>
              }
              style={{
                padding: w16,
                borderRadius: 4,
                width: "auto",
              }}
            />
          </Box>

          <GeneralButton
            label={LOGIN.BUTTON_LOGIN}
            showIcon={true}
            iconSize={h24}
            filled={false}
            disabled={fetching}
            onPress={handleCheckGoogleAccount}
            signUp={false}
            commentText={LOGIN.LABEL_SIGNUP}
            commentOnPress={handleGoToSignUp}
          />

          <Box alignItems={"center"}>
            <Spacer space={h12} />
            <HStack space={w2}>
              <Text>Forgot Password ?</Text>
              <div style={{ cursor: "pointer" }} onClick={handleGoToResetPassword}>
                <Text color={"blue.700"}>Reset Now</Text>
              </div>
            </HStack>
          </Box>
        </Box>
      </LoginContainer>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* view show loading */}
      {fetching ? (
        <Box position={"absolute"} backgroundColor={"gray.300"} width={window.innerWidth} height={window.innerHeight} opacity={0.7}>
          <Loading />
        </Box>
      ) : null}
    </Center>
  );
};
