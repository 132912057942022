import { Box, Input, Radio, Stack, Text } from "native-base";
import React, { useState } from "react";

import { ContentContainer } from "../../components";
import { ENQUIRY } from "../../constants";
import { h128, h4, h8, w100, w16 } from "../../styles";

export const Enquiry = () => {
  const [type, setType] = useState<string>("4");
  // const [category, setCategory] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [detail, setDetail] = useState<string>("");
  const [contact, setContact] = useState<string>("");
  const [location, setLocation] = useState<string>("");

  // const handleUpdateCategory = (event) => {
  //   setCategory(event.target.value);
  // };

  return (
    <ContentContainer>
      <Box backgroundColor={"white"} px={2} py={4} flex={1} borderRadius={8} shadow={"5"}>
        {/* <Box
        flexDirection={"row"}
        // flex={1}
        backgroundColor={"amber.200"}
        alignItems={"center"}
      >
        <label>
          What do we eat?
          <select
            style={{ marginLeft: 24 }}
            value={category}
            onChange={handleUpdateCategory}
          >
            <option value="fruit">Fruit</option>
            <option value="vegetable">Vegetable</option>
            <option value="meat">Meat</option>
          </select>
        </label>
        <p style={{ marginLeft: 24 }}>We eat {category}!</p>
      </Box> */}

        <Box
          flexDirection={"row"}
          alignItems={"center"}
          // backgroundColor={"blue.100"}
          marginBottom={h8}>
          <Text width={w100}>{ENQUIRY.LABEL_TYPE}</Text>
          <Radio.Group
            name="exampleGroup"
            defaultValue="1"
            value={type}
            onChange={(nextValue) => {
              setType(nextValue);
            }}>
            <Stack
              direction={{
                base: "column",
                md: "row",
              }}
              alignItems={{
                base: "flex-start",
                md: "center",
              }}
              space={4}
              w="75%"
              maxW="300px">
              <Radio value="1" colorScheme="red" size="sm" my={1}>
                Complaint
              </Radio>
              <Radio value="2" colorScheme="yellow" size="sm" my={1}>
                Inquiry
              </Radio>
              <Radio value="3" colorScheme="yellow" size="sm" my={1}>
                Suggestion
              </Radio>
              <Radio value="4" colorScheme="green" size="sm" my={1}>
                Compliment
              </Radio>
            </Stack>
          </Radio.Group>
        </Box>

        <Box
          flexDirection={"row"}
          alignItems={"center"}
          // backgroundColor={"lime.50"}
          marginBottom={h4}
          // width={"auto"}
        >
          <Text width={w100}>{ENQUIRY.LABEL_TITLE}</Text>
          <Input
            autoCapitalize={"sentences"}
            backgroundColor={"white"}
            onChangeText={setTitle}
            placeholder={ENQUIRY.LABEL_TITLE}
            value={title}
            variant="outline"
            style={{
              padding: w16,
              borderRadius: 4,
              width: window.innerWidth - 250 * 2,
            }}
          />
        </Box>

        <Box
          flexDirection={"row"}
          // alignItems={"center"}
          // backgroundColor={"lime.50"}
          marginBottom={h4}
          // width={"auto"}
        >
          <Text width={w100}>{ENQUIRY.LABEL_DETAIL}</Text>
          <Input
            autoCapitalize={"sentences"}
            backgroundColor={"white"}
            onChangeText={setDetail}
            multiline
            placeholder={ENQUIRY.LABEL_DETAIL}
            value={detail}
            variant="outline"
            style={{
              padding: w16,
              borderRadius: 4,
              width: window.innerWidth - 250 * 2,
              height: h128,
            }}
          />
        </Box>

        <Box
          flexDirection={"row"}
          alignItems={"center"}
          // backgroundColor={"lime.50"}
          marginBottom={h4}
          // width={"auto"}
        >
          <Text width={w100}>{ENQUIRY.LABEL_CONTACT}</Text>
          <Input
            autoCapitalize={"sentences"}
            backgroundColor={"white"}
            onChangeText={setContact}
            placeholder={ENQUIRY.LABEL_CONTACT}
            value={contact}
            variant="outline"
            style={{
              padding: w16,
              borderRadius: 4,
              width: window.innerWidth - 250 * 2,
            }}
          />
        </Box>

        <Box
          flexDirection={"row"}
          alignItems={"center"}
          // backgroundColor={"lime.50"}
          marginBottom={h4}
          // width={"auto"}
        >
          <Text width={w100}>{ENQUIRY.LABEL_LOCATION}</Text>
          <Input
            autoCapitalize={"sentences"}
            backgroundColor={"white"}
            onChangeText={setLocation}
            placeholder={ENQUIRY.LABEL_LOCATION}
            value={location}
            variant="outline"
            style={{
              padding: w16,
              borderRadius: 4,
              width: window.innerWidth - 250 * 2,
            }}
          />
        </Box>
      </Box>
    </ContentContainer>
  );
};
