import { Box, ScrollView, VStack } from "native-base";
import React, { useContext } from "react";

import { SampleCard } from "../../components";
import { GlobalContext } from "../../context";
import { Divider } from "@mui/material";

interface ICompletedListing {
  handleShowSampleDetail: (value: string) => void;
}

export const CompletedListing = ({ handleShowSampleDetail }: ICompletedListing) => {
  const { globalState } = useContext(GlobalContext);
  const { allClientSamples } = globalState;

  const sampleCardProps = {
    handleShowSampleDetail: handleShowSampleDetail,
  };

  return (
    <div>
      {allClientSamples
        .filter((eachSample) => eachSample.isCompleted === true)
        .map((eachSample, index) => {
          return (
            <div key={`${index}${eachSample.sampleId}`}>
              <SampleCard isCompleted={eachSample.isCompleted} {...sampleCardProps} cardItem={eachSample} />
              {<Divider style={{ marginRight: 20, marginLeft: 20 }} />}
            </div>
          );
        })}
    </div>
  );
};
