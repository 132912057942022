const LOCALHOST = "http://localhost:8001/";
const LIVE_URL = "https://js-mvp-be-petrolab-ten.vercel.app/";
const LIVE_URL_DEV = "https://js-mvp-be-petrolab.vercel.app/";

export const APP_VERSION = "v1.1.0";

export const AUTH_VERIFY_EMAIL = `${LIVE_URL}auth/verify-email`;
export const AUTH_LOGIN = `${LIVE_URL}auth/sign-in`;
export const AUTH_SIGNUP = `${LIVE_URL}auth/sign-up`;
export const RESET_PASSWORD = `${LIVE_URL}auth/reset-password`;

export const VERIFY_CLIENT_PERMISSION = `${LIVE_URL}auth/verify-client-permission`;
export const GET_CLIENT_PROFILE_BY_EMAIL = `${LIVE_URL}client/get-client-by-email`;
export const GET_ALL_SAMPLE_OF_CLIENT = `${LIVE_URL}sample/get-all-sample-of-client`;

export const GET_SAMPLE_BY_REF = `${LIVE_URL}sample/get-sample-by-ref`;

// export const GET_ALL_SAMPLE_IN_PLANT = `${LIVE_URL}sample/get-all-sample-in-plant`;
export const GET_ALL_SAMPLE_OF_AN_INDEX = `${LIVE_URL}sample/get-all-sample-of-an-index-sample`;
// export const GET_ALL_INDEX_SAMPLE_BY_CLIENT_ID = `${LIVE_URL}sample/get-all-index-sample-by-client-id`;
// export const GET_ALL_SAMPLES_IN_PLANT = `${LIVE_URL}sample/get-all-sample-in-plant`;
