import { Alert } from "@mui/material";
import { Box, Center, HStack, Input, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomAlert, GeneralButton, Loading, LoginContainer, Spacer } from "../../components";
import { APP_VERSION, LOGIN, RESET_PASSWORD, VERIFY_CLIENT_PERMISSION } from "../../constants";
import { h12, h24, h8, w16, w2, w8 } from "../../styles";

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [fetching, setFetching] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleAuthLogin = async (email: string) => {
    const authRequest = {
      clientEmail: email,
    };

    try {
      const authPermissionResponse = await fetch(VERIFY_CLIENT_PERMISSION, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(authRequest),
      });
      const authPermissionContent = await authPermissionResponse.json();

      if (authPermissionContent.data.message === "Success" && authPermissionContent.data.result.data.isPermitted === true) {
        return true;
      } else {
        setError(true);
        setErrorMessage("Permission denied. You don't have access to the system.");
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorMessage(error);
    }
  };

  const handleResetCredential = () => {
    setEmail("");
  };

  const handleResetPassword = async () => {
    setFetching(true);

    // const userVerified = await handleAuthLogin(email);
    const userVerified = true;
    const forgotPasswordRequest = {
      email: email,
    };

    if (typeof userVerified === "boolean" && userVerified === true) {
      try {
        const resetPasswordResponse = await fetch(RESET_PASSWORD, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(forgotPasswordRequest),
        });
        // const authSignInResponse: any = await handleLoginFromClient();
        const resetPasswordContent = await resetPasswordResponse.json();
        // console.log("resetPasswordContent", resetPasswordContent);
        if (resetPasswordContent.data.message === "Success") {
          setSuccessMessage("Email sent successfully !");
          //   navigate("/login");
        } else {
          // console.log("failed to add new parameter");
          setError(true);
          setErrorMessage("ERROR_MESSAGE: FAILED TO RESET PASSWORD");
        }
      } catch (error) {
        // console.log(error);
        setError(true);
        setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
      }
    }

    setFetching(false);
    handleResetCredential();
  };

  const handleBackToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }

    if (successMessage !== "") {
      setTimeout(() => {
        setSuccessMessage("");
        navigate("/login");
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, successMessage]);

  return (
    <Center>
      <LoginContainer title={"PetroLab"} subtitle={APP_VERSION} subLabel={"Reset Password"}>
        <Box>
          <Box marginBottom={h8}>
            <Input
              autoCapitalize={"none"}
              onChangeText={setEmail}
              placeholder={LOGIN.PLACEHOLDER_EMAIL}
              value={email}
              variant="filled"
              style={{
                padding: w16,
                borderRadius: 4,
                width: "auto",
              }}
            />
          </Box>

          <GeneralButton
            label={LOGIN.BUTTON_RESET}
            showIcon={true}
            iconSize={h24}
            filled={false}
            disabled={fetching}
            onPress={handleResetPassword}
          />

          <Box alignItems={"center"}>
            <Spacer space={h12} />
            <HStack space={w2}>
              <Text>Back To Login ?</Text>
              <div style={{ cursor: "pointer" }} onClick={handleBackToLogin}>
                <Text color={"blue.700"}>Click Here</Text>
              </div>
            </HStack>
          </Box>
        </Box>
      </LoginContainer>

      {/* alert show success message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {successMessage !== "" ? <Alert severity="success">{successMessage}</Alert> : null}
      </Box>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* view show loading */}
      {fetching ? (
        <Box position={"absolute"} backgroundColor={"gray.300"} width={window.innerWidth} height={window.innerHeight} opacity={0.7}>
          <Loading />
        </Box>
      ) : null}
    </Center>
  );
};
