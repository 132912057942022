import "./App.css";

import { NativeBaseProvider } from "native-base";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { GlobalProvider } from "./context";
import { Dashboard } from "./pages/Dashboard";
import { ForgotPassword, Login, SignUp } from "./pages/Login";

function App() {
  return (
    <div className="App">
      <GlobalProvider>
        <NativeBaseProvider>
          <div style={{ height: window.innerHeight }}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/reset-password" element={<ForgotPassword />} />
                <Route path="dashboard" element={<Dashboard />} />
              </Routes>
            </BrowserRouter>
          </div>
        </NativeBaseProvider>
      </GlobalProvider>
    </div>
  );
}

export default App;
