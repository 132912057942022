import { Box, HStack } from "native-base";
import React from "react";

import { SideMenu } from "./SideMenu";

interface IGlobalContainer {
  children: JSX.Element;
  handleCloseDetailPage?: () => void;
}

export const GlobalContainer = ({
  children,
  handleCloseDetailPage,
}: IGlobalContainer) => {
  const sideMenuProps = {
    handleCloseDetailPage: handleCloseDetailPage,
  };

  return (
    <HStack height={window.innerHeight} maxHeight={window.innerHeight}>
      <SideMenu {...sideMenuProps} />
      <Box
        backgroundColor={"gray.100"}
        width={window.innerWidth - 210}
        maxWidth={window.innerWidth - 210}
        // height={window.innerHeight}
        // maxHeight={window.innerHeight}
      >
        {children}
      </Box>
    </HStack>
  );
};
