import { Backdrop, CircularProgress } from "@mui/material";
import { Box, Text, View } from "native-base";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomAlert, GlobalContainer } from "../components";
import { GET_ALL_SAMPLE_OF_CLIENT } from "../constants";
import { GlobalContext } from "../context";
import { h8, w8 } from "../styles";
import { CompletedListing } from "./dashboard/CompletedListing";
import { OngoingListing } from "./dashboard/OngoingListing";
import { PlantOverview } from "./dashboard/PlantOverview";
import { Enquiry } from "./enquiry/Enquiry";
import { DashboardProfile } from "./profile/DashboardProfile";
import { SampleDetail } from "./sampleDetail/SampleDetail";

export interface ITabRoutes {
  key: string;
  title: string;
  // render: JSX.Element;
}

export const Dashboard = () => {
  const navigate = useNavigate();
  // const privateNavigation = useNavigation<IStackNavigationPrivateProp>();
  // const handleShowSampleDetail = () => {
  //   privateNavigation.navigate("SampleDetail");
  // };
  const { globalState, handleUpdateSamplesOfClient } = useContext(GlobalContext);
  const { selectedMenu: contextSelectedMenu, clientEmail, clientId: contextClientId } = globalState;
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedSampleRef, setSelectedSampleRef] = useState<string | undefined>(undefined);
  const [fetching, setFetching] = useState<boolean>(false);

  const [routes] = useState<ITabRoutes[]>([
    {
      key: "Ongoing",
      title: "Ongoing",
    },
    {
      key: "Completed",
      title: "Completed",
    },
  ]);

  const [activeTab, setActiveTab] = useState<string>("Ongoing");
  const [showDetail, setShowDetail] = useState<boolean>(false);

  const handleCloseDetailPage = () => {
    setShowDetail(false);
  };
  const globalContainerProps = {
    handleCloseDetailPage: handleCloseDetailPage,
  };

  const handleShowSampleDetail = (value: string | undefined) => {
    setSelectedSampleRef(value);
    setShowDetail(!showDetail);
  };
  const contentProps = {
    handleShowSampleDetail: handleShowSampleDetail,
  };

  const sampleDetailsProps = {
    handleShowSampleDetail: handleShowSampleDetail,
    selectedSampleRef: selectedSampleRef,
    setError: setError,
    setErrorMessage: setErrorMessage,
  };

  const handleUpdateTab = (value: string) => {
    setActiveTab(value);
  };

  const handleGetAllSampleOfClient = async (clientId: string) => {
    const request = {
      clientId: clientId,
    };
    try {
      const samplesResponse = await fetch(GET_ALL_SAMPLE_OF_CLIENT, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
      const samplesContent = await samplesResponse.json();

      if (samplesContent.data.message === "Success") {
        const samplesOfClient = samplesContent.data.result.sampleItems;
        await handleUpdateSamplesOfClient(samplesOfClient);
      }
    } catch (error) {
      console.log(error);
      // setError(true);
      // setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  let tabContent: JSX.Element = <View />;
  switch (activeTab) {
    case "Ongoing":
      tabContent = <OngoingListing {...contentProps} />;
      break;
    case "Completed":
      tabContent = <CompletedListing {...contentProps} />;
      break;
    default:
      tabContent = (
        <View>
          <Text>No content</Text>
        </View>
      );
      break;
  }

  let menuContent = (
    <Fragment>
      <PlantOverview activeTab={activeTab} handleUpdateTab={handleUpdateTab} tabRoutes={routes}>
        {tabContent}
      </PlantOverview>
    </Fragment>
  );
  switch (contextSelectedMenu) {
    case "Analysis":
      menuContent = (
        <Fragment>
          <PlantOverview activeTab={activeTab} handleUpdateTab={handleUpdateTab} tabRoutes={routes}>
            {tabContent}
          </PlantOverview>
        </Fragment>
      );
      break;
    case "Enquiry":
      menuContent = (
        <Fragment>
          <Enquiry />
        </Fragment>
      );
      break;
    case "Profile":
      menuContent = (
        <Fragment>
          <DashboardProfile />
        </Fragment>
      );
      break;

    default:
      menuContent = (
        <View>
          <Text>No content</Text>
        </View>
      );
      break;
  }

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      // fetch data
      // console.log("contextClientId", contextClientId);
      await handleGetAllSampleOfClient(contextClientId);
      setFetching(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSelectedMenu]);

  useEffect(() => {
    // const fetchData = async () => {
    //   setFetching(true);
    //   // fetch data
    //   // console.log("contextClientId", contextClientId);
    //   await handleGetAllSampleOfClient(contextClientId);
    //   setFetching(false);
    // };
    // if (getLocalStorage("client-id") === "" && getLocalStorage("client-email") === "") {
    //   navigate("/login");
    // } else {
    //   fetchData();
    // }
    // console.log("clientEmail", clientEmail);
    if (clientEmail === "") {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalContainer {...globalContainerProps}>
      <Fragment>
        {showDetail ? <SampleDetail {...sampleDetailsProps} /> : <Fragment>{menuContent}</Fragment>}
        {/* alert show error message  */}
        <Box position={"absolute"} right={w8} top={h8}>
          {error ? <CustomAlert alertMessage={errorMessage} /> : null}
        </Box>

        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={fetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    </GlobalContainer>
  );
};
