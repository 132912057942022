import { Box, Divider, Heading, HStack, Image, Text, View } from "native-base";
import React, { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../../context";
import { h4, h8, w2, w4 } from "../../styles";
import { setLocalStorage } from "../../utils";

interface ISidemenu {
  handleCloseDetailPage?: () => void;
}

export const SideMenu = ({ handleCloseDetailPage }: ISidemenu) => {
  const navigate = useNavigate();
  // const navigation = useNavigation<IStackNavigationProp>();
  const { globalState, handleUpdateSelectedMenu, handleResetGlobal } = useContext(GlobalContext);
  const { clientName, menuItems, selectedMenu, clientEmail } = globalState;

  const handleUpdateMenu = (value: string) => {
    if (handleCloseDetailPage !== undefined) {
      handleCloseDetailPage();
    }
    handleUpdateSelectedMenu(value);
  };
  const handleLogOut = async () => {
    setLocalStorage("id-token", "");
    await handleResetGlobal();
    navigate("/login");
    // navigation.navigate("Public");
  };

  return (
    <div style={{ backgroundColor: "#31304D" }}>
      <Box width={210} height={window.innerHeight} maxHeight={window.innerHeight} paddingX={4} paddingTop={8} shadow={8}>
        {/* logo & company name */}
        <Box alignItems={"start"} marginBottom={h8}>
          <Box
            // flexDirection={"row"}
            alignItems={"start"}
            justifyContent={"start"}>
            <Image
              size={40}
              alt="petrolab logo"
              borderRadius={100}
              source={require("../../assets/Logo Petrolab final.jpg")}
              fallbackSource={{
                uri: "https://www.w3schools.com/css/img_lights.jpg",
              }}
              marginBottom={h4}
            />

            <Box justifyContent={"start"} alignItems={"start"}>
              <Heading size={"sm"} fontWeight={300} color={"cyan.50"} opacity={0.8}>
                Welcome,
              </Heading>
              <Box
                //  backgroundColor={"amber.200"}
                justifyContent={"start"}>
                <Heading color={"cyan.50"} size={"sm"}>
                  {clientName}
                </Heading>
              </Box>
              <Heading color={"cyan.50"} size={"xs"} fontWeight={500} opacity={0.6}>
                {clientEmail}
              </Heading>
            </Box>
          </Box>

          {/* divider of title in sidemenu */}
          <Divider
            marginTop={4}
            my="2"
            thickness={"4"}
            _light={{
              bg: "lightBlue.200",
            }}
          />
        </Box>

        {/* pages available */}
        <Fragment>
          {menuItems.map((item, index) => {
            const handleSelectMenu = () => {
              handleUpdateMenu(item);
            };
            const isMenuSelected = item === selectedMenu;

            return (
              <div onClick={handleSelectMenu} style={{ cursor: "pointer" }}>
                <Box
                  // paddingBottom={h4}
                  key={index}
                  paddingLeft={w4}
                  marginBottom={h8}>
                  <Box>
                    <HStack justifyContent="space-between" key={item + index.toString()}>
                      {/* <Icon
                      as={Ionicons}
                      name={"flask"}
                      size="6"
                      color="cyan.50"
                      style={isMenuSelected ? {} : { opacity: 0.5 }}
                    /> */}

                      <View
                        paddingLeft={w2}
                        flex={1}
                        borderLeftWidth={"1"}
                        borderLeftColor={isMenuSelected ? "lightBlue.50" : "coolGray.50"}>
                        <Text width="100%" textAlign="left" mx="2" color={"lightBlue.50"} fontWeight={isMenuSelected ? "bold" : "normal"}>
                          {item}
                        </Text>
                      </View>
                    </HStack>
                  </Box>
                </Box>
              </div>
            );
          })}
        </Fragment>

        <div onClick={handleLogOut} style={{ cursor: "pointer" }}>
          <Box
            position={"absolute"}
            bottom={24}
            alignItems={"center"}
            justifyContent={"center"}
            borderWidth={"1"}
            borderRadius={4}
            width={180}
            borderColor={"cyan.50"}
            // onPress={handleLogOut}
            // flex={1}
            py={2}
            opacity={0.8}>
            <Text color={"cyan.50"}>Log out</Text>
          </Box>
        </div>
      </Box>
    </div>
  );
};
