import { Box } from "native-base";
import React, { ReactNode } from "react";

import { h4, w4 } from "../../styles";

interface IContentContainer {
  children: ReactNode;
}

export const ContentContainer = ({ children }: IContentContainer) => {
  return (
    <Box
      px={w4}
      py={h4}
      height={window.innerHeight}
      maxHeight={window.innerHeight}
      // marginBottom={"1/4"}
    >
      {children}
    </Box>
  );
};
