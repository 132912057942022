import { Tab, Tabs } from "@mui/material";
import { Box, Heading, HStack, Image, ScrollView, VStack } from "native-base";
import React, { Fragment, useEffect, useState } from "react";

import { BackIcon, ContentContainer, LabelledItemBoxed, Spacer } from "../../components";
import { GET_SAMPLE_BY_REF } from "../../constants";
import { ISample } from "../../context";
import { h48 } from "../../styles";

interface ISampleDetail {
  handleShowSampleDetail: (value: string | undefined) => void;
  selectedSampleRef: string | undefined;
  setErrorMessage: (value: string) => void;
  setError: (value: boolean) => void;
}

export const SampleDetail = ({ handleShowSampleDetail, selectedSampleRef, setError, setErrorMessage }: ISampleDetail) => {
  const [sampleData, setSampleData] = useState<ISample | undefined>();
  const [reportTabs, setReportTabs] = useState<number>(0);

  // clientEmail: req.body.clientEmail,
  //     sampleId: req.body.sampleId,
  //     sampleName: req.body.sampleName,
  //     sampleType: req.body.sampleType,
  //     samplePoint: req.body.samplePoint,
  //     samplingDate: req.body.samplingDate,
  //     receivedDate: req.body.receivedDate,
  //     isCompleted: req.body.isCompleted,

  const handleCloseSampleDetail = () => {
    handleShowSampleDetail(undefined);
  };

  const handleGetSampleByRef = async () => {
    const request = {
      docReference: selectedSampleRef !== undefined ? selectedSampleRef : "",
    };

    try {
      const sampleByRefResponse = await fetch(GET_SAMPLE_BY_REF, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
      const sampleByRefContent = await sampleByRefResponse.json();

      // console.log("sampleByRefContent", sampleByRefContent.data);

      if (sampleByRefContent.data.message === "Success") {
        const samplesOfClient = sampleByRefContent.data.result.data;
        console.log("samplesOfClient", samplesOfClient);
        setSampleData(samplesOfClient);
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET SPECIFIC SAMPLE DATA FOR ADMIN");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleChangeReportTab = (event: React.SyntheticEvent, newValue: number) => {
    setReportTabs(newValue);
  };

  const isNotArrayOfReports = sampleData !== undefined && typeof sampleData.reportRef === "string";

  // console.log("sampleData", sampleData);

  useEffect(() => {
    handleGetSampleByRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainer>
      <ScrollView flex={1} backgroundColor={"white"} borderRadius={4} shadow={"2"} padding={4}>
        {/* back icon component */}
        <Box>
          <BackIcon onPress={handleCloseSampleDetail} />
        </Box>
        {/* title component & picture */}
        <Box>
          <HStack space={4} alignItems={"center"} justifyContent={"center"}>
            <Heading size={"lg"}>Sample ID: {sampleData?.sampleId}</Heading>
            <Box width={8} height={8} borderRadius={20} backgroundColor={sampleData?.isCompleted ? "green.400" : "yellow.400"} />
          </HStack>

          <Spacer space={h48} />
          <Image
            alt="Logo of petrolab"
            source={require("../../assets/Logo Petrolab final.jpg")}
            style={{ width: 100, height: 100, position: "absolute", right: 12, top: -32 }}
          />
        </Box>
        {/* customer & sample information table  || interpretation table */}
        <VStack space={12}>
          {/* customer & sample information table */}
          <Box borderWidth={1}>
            <Box backgroundColor={"amber.200"} borderRadius={4} py={1}>
              <Heading size={"sm"}>Customer and Sample Information</Heading>
            </Box>
            <HStack>
              <Box flex={1} borderRightWidth={1}>
                <LabelledItemBoxed label={"Customer Name:"} item={sampleData?.clientName} />
                <LabelledItemBoxed label={"Requestor"} item={sampleData?.clientRequestor} />
                <LabelledItemBoxed label={"Location:"} item={sampleData?.sampleLocation} />
                <LabelledItemBoxed label={"Sample Point:"} item={sampleData?.samplePoint} />
              </Box>
              <Box flex={1} borderRightWidth={1}>
                <LabelledItemBoxed label={"Sample Type:"} item={sampleData?.sampleType} />
                <LabelledItemBoxed label={"Sample Name:"} item={sampleData?.sampleName} />
                <LabelledItemBoxed label={"Sampling Date:"} item={sampleData?.samplingDate} />
                <LabelledItemBoxed label={"Received Date:"} item={sampleData?.receivedDate} />
              </Box>
            </HStack>
          </Box>

          {sampleData?.reportRef !== undefined && isNotArrayOfReports === false && sampleData?.reportRef.length > 0 ? (
            <div>
              <Box borderBottomWidth={1} borderColor={"divider"}>
                <Tabs value={reportTabs} onChange={handleChangeReportTab} aria-label="basic tabs example">
                  {sampleData?.reportRef.map((eachtab, index) => {
                    return <Tab key={index} label={`Report ${index + 1}`} />;
                  })}
                </Tabs>
              </Box>

              {sampleData.reportRef.map((eachReport, index) => {
                return (
                  <div key={index} hidden={index !== reportTabs}>
                    <object data={eachReport} type="application/pdf" width="100%" height={window.innerHeight}>
                      <p>
                        Alternative text - include a link <a href={eachReport}>to the PDF!</a>
                      </p>
                    </object>
                  </div>
                );
              })}
            </div>
          ) : null}

          {sampleData?.reportRef !== undefined && isNotArrayOfReports === true ? (
            <div>
              <object data={sampleData.reportRef as unknown as string} type="application/pdf" width="100%" height={window.innerHeight}>
                <p>
                  Alternative text - include a link <a href={sampleData.reportRef as unknown as string}>to the PDF!</a>
                </p>
              </object>
            </div>
          ) : null}
        </VStack>
      </ScrollView>
    </ContentContainer>
  );
};
